import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Upload, Button, Input, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FlexCenter } from '../styles/commonStyled';
import { handleUploadFile } from '../actions/api';
import { URL } from '../data/constants';

const Container = styled.div`
  .ant-form-item-label > label {
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* tile uploaded pictures */
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }

  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
`;

const ButtonContainer = styled.div`
  ${FlexCenter}
  button {
    margin-top: 50px;
    width: 150px;
    margin-right: 10px;
  }
`;

const normFile = (e) => {
  console.log(e);
  if (e.fileList.length > 0 && e.fileList[0].response) {
    return e.fileList.map((item) => ({
      uid: item.response.key,
      name: item.name,
      status: item.status,
      url: URL.imageUrl + item.response.key,
    }));
  }
};
const PostingFrom = ({ data, onFinish, onCancel, category }) => {
  const [uploadImage, setUploadImage] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
      if (data.imageUrl) {
        setUploadImage(data.imageUrl);
      }
      if (data.fileUrl) {
        setUploadFile(data.fileUrl);
      }
    }
  }, [data, form]);

  const handleUploadRequest = async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    await handleUploadFile({
      onSuccess,
      onError,
      file,
      onProgress,
    });

    return {
      abort() {
        console.log('upload progress is aborted.');
      },
    };
  };

  return (
    <Container>
      <Form
        initialValues={{
          status: true,
        }}
        form={form}
        onFinish={onFinish}
      >
        {category && (
          <Form.Item
            name='type'
            label='카테고리'
            rules={[
              {
                required: true,
                message: 'Please input your name',
              },
            ]}
          >
            <Select>
              {Object.keys(category).map((key) => (
                <Select.Option key={key} value={key}>
                  {category[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name='title'
          label='제목'
          rules={[
            {
              required: true,
              message: 'Please input your name',
            },
          ]}
        >
          <Input placeholder='제목' />
        </Form.Item>
        <Form.Item name='content' label='내용'>
          <Input.TextArea style={{ height: 300 }} />
        </Form.Item>
        <Form.Item
          name='imageUrl'
          label='이미지'
          valuePropName='imageUrl'
          getValueFromEvent={normFile}
        >
          <Upload
            onChange={({ fileList }) => setUploadImage(fileList)}
            customRequest={handleUploadRequest}
            fileList={uploadImage}
            listType='picture'
            className='upload-list-inline'
          >
            {uploadImage.length > 0 ? null : (
              <Button icon={<UploadOutlined />}>이미지 추가하기</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item label='링크' style={{ marginBottom: 0 }}>
          <Form.Item
            name='link'
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input placeholder='링크 이름' />
          </Form.Item>
          <Form.Item
            name='linkUrl'
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input placeholder='링크 주소' />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name='fileUrl'
          valuePropName='fileUrl'
          getValueFromEvent={normFile}
          label='파일'
        >
          <Upload
            onChange={({ fileList }) => setUploadFile(fileList)}
            customRequest={handleUploadRequest}
            fileList={uploadFile}
            listType='picture'
            className='upload-list-inline'
          >
            <Button icon={<UploadOutlined />}>파일 추가하기</Button>
          </Upload>
        </Form.Item>
        <ButtonContainer>
          <Button type='primary' htmlType='submit'>
            {data ? '수정하기' : '글쓰기'}
          </Button>
          {data && <Button onClick={onCancel}>취소</Button>}
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default PostingFrom;
