import React from 'react';
import { Modal, Button } from 'antd';
import PostingFrom from './PostingFrom';

const Writing = ({ visible, onModalVisible, onFinish, category }) => {
  return (
    <Modal
      title='글쓰기'
      centered
      visible={visible}
      onCancel={() => onModalVisible(false)}
      onOk={onFinish}
      width={650}
      footer={[
        <Button
          key='close'
          type='defalut'
          htmlType='submit'
          onClick={() => onModalVisible(false)}
        >
          취소
        </Button>,
      ]}
    >
      <PostingFrom category={category} onFinish={onFinish} />
    </Modal>
  );
};

export default Writing;
