import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    width: 100vw;
    height: 100%;
    min-height: 100%;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    --color-background: white;
    --color-gray: gray;
    --color-red: red;
    --color-content-background: #f0f2f5
  }
  .ant-layout-content {
    min-height: unset;
  }


`;

export default GlobalStyle;
