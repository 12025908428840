import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { CATEGORY_INQUIRY } from '../constants';
const color = {
  'Taobao Business': 'red',
  'Tmall Business': 'gold',
  'Tmall Agency': 'blue',
  'Alibaba Global': 'green',
  Etc: '',
};

const categoryFilter = Object.keys(CATEGORY_INQUIRY).map((key) => ({
  text: CATEGORY_INQUIRY[key],
  value: key,
}));
const boardColumns = () => [
  {
    title: '문의 분류',
    dataIndex: 'inquiryType',
    key: 'inquiryType',
    filters: categoryFilter,
    onFilter: (value, record) => record.inquiryType.indexOf(value) > -1,
    render: (type) => <Tag color={color[type]}>{CATEGORY_INQUIRY[type]}</Tag>,
  },
  {
    title: '회사',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: '이메일',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    render: (date) =>
      moment(new Date(date)).local().format('YYYY-MM-DD HH:mm:ss'),
  },
];

export default boardColumns;
