import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Table, Button } from 'antd';
import boardColumns from '../data/columns/boardColumns';
import Writing from '../components/Writing';
import { useHistory, useParams } from 'react-router-dom';
import { handleSetPost, handleGetPostList } from '../actions/api';
import Spinner from '../components/Spinner';
import { CATEGORY_BOARD } from '../data/constants';
import PageTitle from '../components/PageTitle';

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
	button {
		width: 100px;
		height: 36px;
	}
`;

const Board = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [showWriteModal, setShowWriteModal] = useState(false);
	const [language, setLanguage] = useState('KR');
	const [list, setList] = useState([]);
	const history = useHistory();
	const dispatch = useDispatch();

	const handleLoadDetail = (data) => {
		history.replace(`/board/${language.toLocaleLowerCase()}/${data.SK}`);
	};

	// 글쓰기
	const handleFinish = async (data) => {
		dispatch({
			type: 'IS_LOADING',
			payload: true,
		});
		const sent = await handleSetPost('setPost', { ...data, language });
		if (sent) {
			dispatch({
				type: 'IS_LOADING',
				payload: false,
			});
		}
	};
	//  삭제
	const handleDeleteRow = async (data) => {
		console.log(data);
		dispatch({
			type: 'IS_LOADING',
			payload: true,
		});
		const sentDelete = await handleSetPost('setPost', {
			...data,
			language,
			delete: 'true',
		});
		if (sentDelete) {
			dispatch({
				type: 'IS_LOADING',
				payload: false,
			});
		}
	};

	const { lang } = useParams();
	useEffect(() => {
		setIsLoading(true);
		setLanguage(lang.toUpperCase());

		// 목록 state list
		(async () => {
			await handleGetPostList(
				'getPostList',
				'board',
				null,
				lang.toUpperCase(),
				setList
			);
			setIsLoading(false);
		})();
	}, [lang]);

	return (
		<>
			{isLoading && <Spinner />}
			<PageTitle text='PANDA NEWS BOARD' />
			<ButtonContainer>
				<Button type='primary' onClick={() => setShowWriteModal(true)}>
					글쓰기
				</Button>
			</ButtonContainer>
			<Table
				columns={boardColumns(handleDeleteRow, handleLoadDetail)}
				dataSource={list}
			/>
			{showWriteModal && (
				<Writing
					category={CATEGORY_BOARD}
					visible={showWriteModal}
					onModalVisible={setShowWriteModal}
					onFinish={handleFinish}
				/>
			)}
		</>
	);
};

export default Board;
