import React from 'react';
import styled from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PublicLayout = ({ children }) => {
  return <Background>{children}</Background>;
};

export default PublicLayout;
