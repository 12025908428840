import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const FlexCenter = css`
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
`;

export const CommonButtonStyled = styled(Button)`
  height: 36px;
  width: 100px;
`;
