import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
`;
const Spinner = () => {
  return (
    <Container>
      <Spin size='large' />
    </Container>
  );
};

export default Spinner;
