import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const Container = styled.div`
  margin-bottom: 50px;
`;

const PageTitle = ({ text }) => {
  return (
    <Container>
      <Typography.Title level={3}>{text}</Typography.Title>
    </Container>
  );
};

export default PageTitle;
