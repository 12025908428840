import React from 'react';
import { Modal, Form, Input, Button } from 'antd';

const NewPasswordModal = ({ visible, onFinish, onModalVisible }) => {
  return (
    <Modal
      visible={visible}
      centered
      title='새로운 비밀번호 설정하기'
      footer={[
        <Button
          key='close'
          type='defalut'
          htmlType='submit'
          onClick={() => onModalVisible(false)}
        >
          취소
        </Button>,
      ]}
    >
      <Form labelAlign='left' onFinish={onFinish} layout='vertical'>
        <Form.Item label='변경 비밀번호' hasFeedback>
          <Input.Password placeholder='새 비밀번호' />
        </Form.Item>

        <Form.Item
          label='변경 비밀번호 확인'
          hasFeedback
          validateStatus='error'
        >
          <Input.Password allowClear placeholder='새 비밀번호 확인' />
        </Form.Item>
        <Form.Item
          style={{ paddingTop: 30, marginBottm: 0 }}
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button type='primary' htmlType='submit'>
            비밀번호 변경 하기
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewPasswordModal;
