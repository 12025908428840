export const CATEGORY_BOARD = {
  notice: '공지사항',
  news: '판다 뉴스',
  publishing: '보도자료',
};
export const CATEGORY_RECRUIT = {
  close: '마감',
  hiring: '채용중',
};

export const CATEGORY_INQUIRY = {
  'Taobao Business': '타오바오 식품관 입점',
  'Tmall Business': '티몰 뷰티관 입점',
  'Tmall Agency': '티몰 운영대행 문의',
  'Alibaba Global': '알리바바 글로벌 문의',
  Etc: '기타',
};

export const URL = {
  imageUrl: `https://${process.env.REACT_APP_API_ENDPOINT}.company-pandakorea.com/cdn/public/`,
  api: `https://${process.env.REACT_APP_API_ENDPOINT}.company-pandakorea.com/`,
};
