import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Checkbox, Card, Modal } from 'antd';
import { Auth } from 'aws-amplify';
import PublicLayout from '../layout/PublicLayout';
import NewPasswordModal from '../components/NewPasswordModal';
import logo from '../assets/header-logo.png';
import styled from 'styled-components';
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const CardStyle = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormStyle = styled(Form)`
  width: 300px;
`;
const ButtonStyle = styled(Button)`
  width: 100%;
`;
const Login = () => {
  const [showNewPassword, setShowNewpassword] = useState(false);
  const dispatch = useDispatch();

  const handleSetPassword = (values) => {
    console.log(values);
  };

  const onFinish = async (values) => {
    try {
      const user = await Auth.signIn(values.username, values.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Modal.confirm({
          centered: true,
          title: '비밀번호 재설정',
          content: '해당 아이디의 비밀번호 변경이 필요합니다.',
          okText: '새로운 비밀번호 설정하기',
          cancelText: '취소',
          onOk() {
            setShowNewpassword(true);
          },
          onCancel() {},
        });
        Auth.completeNewPassword(user, values.password)
          .then((newUser) => {
            dispatch({
              type: 'SIGN_IN',
              payload: newUser,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        dispatch({
          type: 'SIGN_IN',
          payload: user,
        });
      }
    } catch (err) {
      Modal.warning({
        centered: true,
        title: err.name,
        content: err.message,
      });
      console.error(err);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <NewPasswordModal
        onModalVisible={setShowNewpassword}
        visible={showNewPassword}
        onFinish={handleSetPassword}
      />
      <PublicLayout>
        <CardStyle
          title={<img src={logo} alt='panda korea logo' />}
          bordered={true}
        >
          <FormStyle
            {...layout}
            layout='vertical'
            name='basic'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input placeholder='Username' />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password placeholder='Password' />
            </Form.Item>
            <Form.Item name='remember' valuePropName='checked'>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <ButtonStyle type='primary' htmlType='submit'>
                Login
              </ButtonStyle>
            </Form.Item>
          </FormStyle>
        </CardStyle>
      </PublicLayout>
    </>
  );
};

export default Login;
