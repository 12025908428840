import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { Button, Popconfirm, Row } from 'antd';
import { CommonButtonStyled } from '../styles/commonStyled';
import PostingFrom from './PostingFrom';
import Spinner from './Spinner';
import PageTitle from './PageTitle';
import { CATEGORY_BOARD, CATEGORY_RECRUIT, URL } from '../data/constants';
import { getSetUrl, getUrl } from '../utills/common';
import { handleSetPost } from '../actions/api';

const DetailContainer = styled.div`
  background-color: var(--color-background);
  border-top: solid 2px #000000;
  text-align: center;
  margin-bottom: 4.5rem;

  section,
  article {
    display: flex;
  }

  section,
  article {
    padding: 0 3.125rem;
  }

  section {
    justify-content: space-between;
    align-items: center;
    height: 7.6875rem;
    line-height: 1.5;
    border-bottom: solid 1px #dddddd;
    margin-bottom: 4.25rem;
    h1 {
      font-size: 1.5625rem;
      color: black;
    }
    aside {
      color: ${({ $onGoing }) => ($onGoing ? 'red' : 'gray')};
    }
  }
  article {
    flex-direction: column;
    font-size: 0.9375rem;
    line-height: 2;
    text-align: left;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
    border-bottom: solid 2px #000000;
    p {
      margin-top: 40px;
      line-height: 2;
    }
    img {
      align-self: center;
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  ul {
    display: flex;
    border-top: solid 1px #dddddd;
    li {
      display: flex;
      align-items: center;
      flex: 9;
      width: 100%;
      height: 3.9375rem;
      font-size: 1.0625rem;
      line-height: 1;
      font-weight: 300;
      a {
        width: 100%;
        height: 100%;
        padding: 0 5px;
        display: inline-block;
        text-align: left;
        line-height: 3.9375rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    li:first-child {
      flex: 1;
      color: var(--color-gray);
      padding-left: 20px;
      span {
        margin-left: 15px;
        font-size: 0.75rem;
      }
    }
  }
  ul.last {
    border-bottom: solid 1px #dddddd;
    margin-bottom: 2.25rem;
  }
  a.link {
    align-self: center;
    padding: 10px;
    margin: 20px 30px;
    text-align: center;
    background-color: var(--color-red);
    color: #ffffff;
    border-radius: 10px;
  }
  div.tag {
    cite {
      margin-left: 10px;
      background-color: var(--color-gray);
      padding: 4px 10px;
      border-radius: 10px;
      color: #ffffff;
    }
  }
`;

const ButtonStyled = styled(Button)`
  height: 50px;
  width: 300px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  ${CommonButtonStyled} {
    margin-left: 10px;
  }
`;
const category = (pathname) => {
  switch (pathname) {
    case 'board':
      return CATEGORY_BOARD;
    case 'recruit':
      return CATEGORY_RECRUIT;
    default:
      return {};
  }
};
const getTitle = (pathname) => {
  switch (pathname) {
    case 'board':
      return 'PANDA NEWS';
    case 'recruit':
      return 'RECRUITMENT';
    case 'guide':
      return ' GUIDE';
    default:
      return '';
  }
};
const BoardDetail = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [current, setCurrent] = useState({});
  const isApply =
    'type' in current &&
    (current.type === 'hiring' || current.type === 'close');
  const { lang, id, mall } = useParams();
  const { pathname } = useLocation();
  const PK = pathname.split('/')[1];
  const handleDelete = async () => {
    setIsLoading(true);
    const changedCurrent = {
      ...current,
      delete: 'true',
    };
    try {
      const result = await handleSetPost(getSetUrl(PK), changedCurrent);
      if (result) {
        return history.push(`/${PK}/${lang ? lang : mall}`);
      }
    } catch (error) {
      console.error(error);
      return history.push(`/${PK}/${lang ? lang : mall}`);
    }
  };

  const handleEditItem = async (values) => {
    setIsLoading(true);
    const changedCurrent = {
      ...values,
      SK: current.SK,
      language: lang ? lang.toUpperCase() : 'KR',
      date: moment(),
    };
    try {
      const result = await handleSetPost(getSetUrl(PK), changedCurrent);
      if (result) {
        setCurrent(changedCurrent);
      }
      setEditMode(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setEditMode(false);
    }
  };

  useEffect(() => {
    (async () => {
      const url = URL.api + getUrl(PK);
      await axios
        .get(url, { params: { PK: PK, SK: id } })
        .then((response) => {
          setIsLoading(false);
          if (response.data.Items[0]) setCurrent(response.data.Items[0]);
          else history.push('/');
        })
        .catch((e) => {
          return history.push('/');
        });
    })();
  }, [history, PK, id]);

  if (isLoading) return <Spinner />;
  return editMode ? (
    <PostingFrom
      category={category(PK)}
      data={current}
      onFinish={handleEditItem}
      onCancel={() => setEditMode(false)}
    />
  ) : (
    <>
      <PageTitle
        text={mall ? mall.toUpperCase() + getTitle(PK) : '' + getTitle(PK)}
      />
      <ButtonContainer>
        <CommonButtonStyled onClick={() => setEditMode(true)}>
          수정
        </CommonButtonStyled>
        <Popconfirm
          title='선택된 글을 삭제 하시겠습니까?'
          onConfirm={handleDelete}
        >
          <CommonButtonStyled type='primary' danger>
            삭제
          </CommonButtonStyled>
        </Popconfirm>
      </ButtonContainer>
      <DetailContainer $onGoing={isApply}>
        <section>
          <h1>{current.title}</h1>
          {isApply ? (
            <aside>{CATEGORY_RECRUIT[current.type]}</aside>
          ) : (
            <aside>
              {moment(new Date(current.date))
                .local()
                .format('YYYY-MM-DD HH:mm:ss')}
            </aside>
          )}
        </section>
        <article>
          {current.imageUrl && (
            <img src={current.imageUrl[0].url} alt={current.imageUrl[0].name} />
          )}
          <p>{current.content}</p>
          {current.linkUrl && (
            <a
              className='link'
              href={current.linkUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {current.link}
            </a>
          )}
          {current.fileUrl &&
            current.fileUrl.map((file) => (
              <a key={file.uid} href={file.url} download>
                {file.name}
              </a>
            ))}
        </article>
      </DetailContainer>
      <Row justify='center'>
        <ButtonStyled>
          <Link to={`/${PK}/${lang ? lang : mall}`}>목록보기</Link>
        </ButtonStyled>
      </Row>
    </>
  );
};

export default BoardDetail;
