import React from 'react';
import styled from 'styled-components';
import { Button, Layout, Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import logo from '../assets/header-logo.png';
import { FlexCenter } from '../styles/commonStyled';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import menus from '../data/menus';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const LayoutStyled = styled(Layout)`
  min-height: 100%;
  height: 100%;
`;
const LogoStyled = styled.div`
  ${FlexCenter}
  cursor: pointer;
  background-color: var(--color-background);
  height: 64px;
`;
const HeaderStyled = styled(Header)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px;
`;

const ContentStyled = styled(Content)`
  background-color: var(--color-content-background);
  padding: 24px;
  min-height: 360px;
`;
const BaseLayout = ({ children }) => {
  const [openKeys, setOpenKeys] = React.useState(['/']);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const splitedPath = pathname.split('/');
    const selectedKey =
      pathname === '/'
        ? '/'
        : `/${splitedPath[1]}${splitedPath[2] ? `/${splitedPath[2]}` : ''}`;
    setOpenKeys([selectedKey]);
  }, [pathname]);

  const handleLogout = () => {
    dispatch({
      type: 'SIGN_OUT',
    });
  };

  return (
    <LayoutStyled>
      <Sider
        breakpoint='lg'
        collapsedWidth='0'
        // onBreakpoint={(broken) => {
        // 	console.log(broken);
        // }}
        // onCollapse={(collapsed, type) => {
        // 	console.log(collapsed, type);
        // }}
      >
        <LogoStyled onClick={() => history.push('/')}>
          <img src={logo} alt='panda logo' />
        </LogoStyled>
        <Menu
          mode='inline'
          selectedKeys={openKeys}
          style={{ height: '100%', borderRight: 0 }}
        >
          {menus.map((item) => {
            if (item.subMenus) {
              return (
                <SubMenu key={item.label} icon={item.icon} title={item.name}>
                  {item.subMenus.map((sub) => (
                    <Menu.Item key={sub.label} icon={sub.icon}>
                      <Link to={sub.path}></Link>
                      {sub.name}
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={item.label} icon={item.icon}>
                  <Link to={item.path}></Link>
                  {item.name}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </Sider>
      <Layout>
        <HeaderStyled>
          <Button
            ghost
            color='#ffffff'
            icon={<LogoutOutlined />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </HeaderStyled>
        <ContentStyled>
          <div>{children}</div>
        </ContentStyled>
        <Footer style={{ textAlign: 'center' }}>
          Panda Korea ©2020 Created by GS ITM
        </Footer>
      </Layout>
    </LayoutStyled>
  );
};

export default BaseLayout;
