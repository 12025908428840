export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getUrl = (pathName) => {
  switch (pathName) {
    case 'board':
      return 'getPost';
    case 'recruitment':
      return 'getRecruit';
    case 'guide':
      return 'getGuide';
    case 'contactus':
      return 'getContactUs';
    default:
      return 'getPost';
  }
};
export const getSetUrl = (pathName) => {
  switch (pathName) {
    case 'board':
      return 'setPost';
    case 'recruit':
      return 'setRecruit';
    case 'guide':
      return 'setGuide';
    default:
      return 'getPost';
  }
};
