import React from 'react';
import { PictureOutlined, MailOutlined } from '@ant-design/icons';

export default [
	{ path: '/', name: '슬라이드', icon: <PictureOutlined />, label: '/' },
	{
		name: '판다 뉴스',
		label: 'board',
		subMenus: [
			{ path: '/board/kr', name: '한국어', icon: '', label: '/board/kr' },
			{ path: '/board/en', name: '영어', icon: '', label: '/board/en' },
			{ path: '/board/cn', name: '중국어', icon: '', label: '/board/cn' },
		],
	},
	{
		name: '채용',
		label: 'recruit',
		subMenus: [
			{
				path: '/recruit/kr',
				name: '한국어',
				icon: '',
				label: '/recruit/kr',
			},
			{
				path: '/recruit/en',
				name: '영어',
				icon: '',
				label: '/recruit/en',
			},
			{
				path: '/recruit/cn',
				name: '중국어',
				icon: '',
				label: '/recruit/cn',
			},
		],
	},
	{
		name: '가이드',
		label: 'guide',
		subMenus: [
			{
				path: '/guide/tmall',
				name: '티몰',
				icon: '',
				label: '/guide/tmall',
			},
			{
				path: '/guide/alibaba',
				name: '알리바바',
				icon: '',
				label: '/guide/alibaba',
			},
			{
				path: '/guide/taobao',
				name: '타오바오',
				icon: '',
				label: '/guide/taobao',
			},
		],
	},
	{
		path: '/contactus',
		name: '문의',
		icon: <MailOutlined />,
		label: '/contactus',
	},
];
