const initState = {
	isLoading: false,
	isSignIn: false,
	username: '',
	email: '',
	emailVerified: false,
	idToken: {
		jwtToken: '',
		expiry: null,
	},
	refreshToken: '',
	accessToken: {
		jwtToken: '',
		expiry: null,
	},
};

export default (state = initState, action) => {
	switch (action.type) {
		case 'IS_LOADING': {
			return { ...state, isLoading: action.payload };
		}
		case 'SIGN_IN': {
			return {
				...state,
				isLoading: false,
				isSignIn: true,
				user: action.payload,
			};
		}
		case 'NEW_PASSWORD_REQUIRED': {
			return {
				...state,
			};
		}
		case 'SIGN_UP': {
			return {
				...state,
			};
		}
		case 'CONFIRM_SIGN_UP': {
			return {
				...state,
			};
		}
		case 'SIGN_OUT': {
			return initState;
		}
		default:
			return state;
	}
};
