import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import { handleGetPostList } from '../actions/api';
import inquiryColumns from '../data/columns/inquiryColumns';
import PageTitle from '../components/PageTitle';
import Spinner from '../components/Spinner';
const CollapseTable = styled.table`
  border-collapse: collapse;
  border: 1px solid #ddd;
  tr:first-child {
    td {
      border-top: 0;
    }
  }
  td {
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  td:first-child {
    width: 100px;
    text-align: center;
    color: #ffffff;
    background-color: #666666;
  }
`;

const Inquiry = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const { lang, id } = useParams();
  useEffect(() => {
    setIsLoading(true);
    // 목록 state list
    (async () => {
      await handleGetPostList(
        'getContactUsList',
        'contactus',
        null,
        'KR',
        setList
      );
      setIsLoading(false);
    })();
  }, [lang, id]);
  if (isLoading) return <Spinner />;
  return (
    <>
      <PageTitle text='CONTACT US' />
      <Table
        expandable={{
          expandedRowRender: (record) => (
            <CollapseTable>
              <tbody>
                <tr>
                  <td>연락처</td>
                  <td>{record.telNo ? record.telNo : ''}</td>
                </tr>
                <tr>
                  <td>유입경로</td>
                  <td>{record.funnelsType ? record.funnelsType : ''}</td>
                </tr>
                <tr>
                  <td>문의내용</td>
                  <td>{record.inquiry}</td>
                </tr>
              </tbody>
            </CollapseTable>
          ),
          rowExpandable: (record) => record.inquiry !== 'Not Expandable',
        }}
        columns={inquiryColumns()}
        dataSource={list}
      />
    </>
  );
};

export default Inquiry;
