import React, { useEffect } from 'react';
import { BrowserRouter, Router, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Amplify, { Auth as awsAuth } from 'aws-amplify';
import history from './utills/history';
import BaseLayout from './layout/BaseLayout';
import Inquiry from './views/Inquiry';
import Login from './views/Login';
import ImageBoard from './views/ImageBoard';
import Board from './views/Board';
import Recruitment from './views/Recruitment';
import Information from './views/Information';
import Spinner from './components/Spinner';
import BoardDetail from './components/BoardDetail';
import 'antd/dist/antd.css';

Amplify.configure({
	Auth: {
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
		region: process.env.REACT_APP_REGION,
	},
	Storage: {
		AWSS3: {
			region: process.env.REACT_APP_REGION,
		},
	},
});

const App = () => {
	const { isSignIn, isLoading } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({
			type: 'IS_LOADING',
			payload: true,
		});
		awsAuth
			.currentAuthenticatedUser({
				bypassCache: true,
			})
			.then((user) => {
				dispatch({
					type: 'SIGN_IN',
					payload: user,
				});
			})
			.catch((err) => {
				dispatch({
					type: 'IS_LOADING',
					payload: false,
				});
				console.log(err);
			});
	}, [dispatch]);
	if (isLoading) return <Spinner />;
	return (
		<BrowserRouter>
			<Router history={history}>
				{isSignIn ? (
					<Switch>
						<BaseLayout>
							<Route exact path='/' component={ImageBoard} />
							<Route exact path='/board/:lang' component={Board} />
							<Route exact path='/board/:lang/:id' component={BoardDetail} />
							<Route exact path='/guide/:mall' component={Information} />
							<Route exact path='/guide/:mall/:id' component={BoardDetail} />
							<Route exact path='/recruit/:lang' component={Recruitment} />
							<Route exact path='/recruit/:lang/:id' component={BoardDetail} />
							<Route exact path='/contactus' component={Inquiry} />
							<Route exact path='/contactus/:id' component={BoardDetail} />
						</BaseLayout>
					</Switch>
				) : (
					<Login />
				)}
			</Router>
		</BrowserRouter>
	);
};

export default App;
