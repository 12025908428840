import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Table, Button } from 'antd';
import guideColumns from '../data/columns/guideColumns';
import Writing from '../components/Writing';
import { useParams } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import Spinner from '../components/Spinner';
import { handleSetPost, handleGetPostList } from '../actions/api';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  button {
    width: 100px;
    height: 36px;
  }
`;

const Information = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showWriteModal, setShowWriteModal] = useState(false);
  const [list, setList] = useState([]);
  const { mall } = useParams();
  const dispatch = useDispatch();

  const handleLoadDetail = (data) => {
    return history.replace(`/guide/${mall}/${data.SK}`);
  };

  // 글쓰기/ 수정하기
  const handleFinish = async (value) => {
    dispatch({
      type: 'IS_LOADING',
      payload: true,
    });
    const sent = await handleSetPost('setGuide', {
      ...value,
      type: mall,
      language: 'KR',
    });
    if (sent) {
      dispatch({
        type: 'IS_LOADING',
        payload: false,
      });
    }
  };
  //  삭제
  const handleDeleteRow = async (data) => {
    dispatch({
      type: 'IS_LOADING',
      payload: true,
    });
    const sentDelete = await handleSetPost('setGuide', {
      ...data,
      type: mall,
      language: 'KR',
      delete: 'true',
    });
    if (sentDelete) {
      dispatch({
        type: 'IS_LOADING',
        payload: false,
      });
    }
  };

  useEffect(() => {
    // 목록 state list
    setIsLoading(true);
    (async () => {
      await handleGetPostList('getGuideList', 'guide', mall, 'KR', setList);
      setIsLoading(false);
    })();
  }, [mall]);

  return (
    <>
      {isLoading && <Spinner />}
      <PageTitle text={`${mall.toUpperCase()} GUIDE BOARD`} />
      <ButtonContainer>
        <Button type='primary' onClick={() => setShowWriteModal(true)}>
          글쓰기
        </Button>
      </ButtonContainer>
      <Table
        columns={guideColumns(handleDeleteRow, handleLoadDetail)}
        dataSource={list}
      />
      {showWriteModal && (
        <Writing
          visible={showWriteModal}
          onModalVisible={setShowWriteModal}
          onFinish={handleFinish}
        />
      )}
    </>
  );
};

export default Information;
