import React, { useState, useEffect, useCallback } from 'react';
import { Divider } from 'antd';
import styled from 'styled-components';
import ImageWall from '../components/ImageWall';
import PageTitle from '../components/PageTitle';
import Spinner from '../components/Spinner';
import { handleGetPostList } from '../actions/api';

const Container = styled.div`
	padding: 20px;
	background-color: var(--color-background);
`;

const ImageBoard = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [sliderImages, setSliderImages] = useState({
		mobileKR: {
			PK: 'slider',
			SK: 2,
			language: 'KR',
			type: 'mobile',
			images: [],
		},
		desktopKR: {
			PK: 'slider',
			SK: 1,
			language: 'KR',
			type: 'desktop',
			images: [],
		},
		mobileEN: {
			PK: 'slider',
			SK: 4,
			language: 'EN',
			type: 'mobile',
			images: [],
		},
		desktopEN: {
			PK: 'slider',
			SK: 3,
			language: 'EN',
			type: 'desktop',
			images: [],
		},
		mobileCN: {
			PK: 'slider',
			SK: 5,
			language: 'CN',
			type: 'mobile',
			images: [],
		},
		desktopCN: {
			PK: 'slider',
			SK: 6,
			language: 'CN',
			type: 'desktop',
			images: [],
		},
	});
	const [popupImages, setPopupImages] = useState({
		KR: {
			PK: 'popup',
			SK: 1,
			language: 'KR',
			images: [],
		},
		EN: {
			PK: 'popup',
			SK: 2,
			language: 'EN',
			images: [],
		},
		CN: {
			PK: 'popup',
			SK: 3,
			language: 'CN',
			images: [],
		},
	});

	const getImages = useCallback(async () => {
		try {
			const [slider, popup] = await Promise.all([
				handleGetPostList('getImageSlider'),
				handleGetPostList('getPopup'),
			]);
			if (popup.length > 0) {
				const sortedPopup = popup.reduce(
					(accumulator, current) => ({
						...accumulator,
						[current.language]: current,
					}),
					{}
				);
				setPopupImages((popup) => ({ ...popup, ...sortedPopup }));
			}
			if (slider.length > 0) {
				const sortedImages = slider.reduce(
					(accumulator, current) => ({
						...accumulator,
						[current.type + current.language]: current,
					}),
					{}
				);
				setSliderImages((slider) => ({ ...slider, ...sortedImages }));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}, []);
	useEffect(() => {
		getImages();
	}, [getImages]);

	return (
		<Container>
			{isLoading && <Spinner />}
			<PageTitle text='랜딩페이지 슬라이드 및 팝업 관리' />
			<section>
				<Divider orientation='left'>한국어 팝업</Divider>
				<ImageWall data={popupImages.KR} limit={1} />
			</section>
			<section>
				<Divider orientation='left'>한국어 데스크탑</Divider>
				<ImageWall data={sliderImages.desktopKR} />
			</section>
			<section>
				<Divider orientation='left'>한국어 모바일</Divider>
				<ImageWall data={sliderImages.mobileKR} />
			</section>
			<section>
				<Divider orientation='left'>영어 팝업</Divider>
				<ImageWall data={popupImages.EN} limit={1} />
			</section>
			<section>
				<Divider orientation='left'>영어 데스크탑</Divider>
				<ImageWall data={sliderImages.desktopEN} />
			</section>
			<section>
				<Divider orientation='left'>영어 모바일</Divider>
				<ImageWall data={sliderImages.mobileEN} />
			</section>
			<section>
				<Divider orientation='left'>중국어 팝업</Divider>
				<ImageWall data={popupImages.CN} limit={1} />
			</section>
			<section>
				<Divider orientation='left'>중국어 데스크탑</Divider>
				<ImageWall data={sliderImages.desktopCN} />
			</section>
			<section>
				<Divider orientation='left'>중국어 모바일</Divider>
				<ImageWall data={sliderImages.mobileCN} />
			</section>
		</Container>
	);
};

export default ImageBoard;
