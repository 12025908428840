import axios from 'axios';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import { URL } from '../data/constants';

export const handleSetPost = async (path, value) => {
  const url = URL.api + path;

  return await axios
    .post(url, value)
    .then(async (response) => {
      console.log(response);
      return true;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const handleGetPostList = (path, PK, type, language, callback) => {
  const url = URL.api + path;
  return axios
    .get(url, { params: { PK, type, language } })
    .then(async (response) => {
      if (response.data.Items) {
        if (callback) {
          return callback(response.data.Items);
        }
        return response.data.Items;
      }
      return false;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const handleUploadFile = ({ file, onSuccess, onProgress, onError }) => {
  return Storage.put(moment().valueOf() + '-' + file.name, file, {
    bucket: 'panda-image-storage-dev',
    level: 'public',
    progressCallback(progress) {
      onProgress(
        {
          percent: Math.round((progress.loaded / progress.total) * 100).toFixed(
            2
          ),
        },
        file
      );
    },
  })
    .then((result) => {
      onSuccess(result, file);
    })
    .catch(onError);
};
