import React from 'react';
import { Tag, Button, Popconfirm } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { CATEGORY_BOARD } from '../constants';
const color = { notice: 'red', news: 'blue', publishing: 'gold' };
const TitleStyled = styled.div`
  cursor: pointer;
`;
const categoryFilter = Object.keys(CATEGORY_BOARD).map((key) => ({
  text: CATEGORY_BOARD[key],
  value: key,
}));
const boardColumns = (onDeleteRow, onLoadDetail) => [
  {
    title: '카테고리',
    dataIndex: 'type',
    key: 'type',
    filters: categoryFilter,
    onFilter: (value, record) => record.type.indexOf(value) > -1,
    render: (type) => <Tag color={color[type]}>{CATEGORY_BOARD[type]}</Tag>,
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    render: (title, record) => (
      <TitleStyled onClick={() => onLoadDetail(record)}>{title}</TitleStyled>
    ),
  },
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    render: (date) =>
      moment(new Date(date)).local().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Popconfirm
        title='선택된 글을 삭제 하시겠습니까?'
        onConfirm={() => onDeleteRow(record)}
      >
        <Button type='text' danger>
          Delete
        </Button>
      </Popconfirm>
    ),
  },
];

export default boardColumns;
