import React, { useState, useEffect } from 'react';
import { Upload, Modal, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import { handleSetPost } from '../actions/api';
import { URL } from '../data/constants';

const ImageWall = ({ data, limit }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isSavingLink, setIsSavingLink] = useState(false);
  const [imageList, setImageList] = useState([]);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  useEffect(() => {
    if (!data.images) {
      return;
    }
    setImageList(data.images);
  }, [data]);
  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewImage(null);
    setPreviewTitle('');
  };

  const handlePreview = async (file) => {
    setPreviewVisible(true);
    setPreviewImage(file);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ file, fileList }) => {
    if (file.status === 'removed') {
      data.images = fileList;
      handleSetPost('setImageSlider', data);
    }
    setImageList(fileList);
  };

  const handleUploadRequest = async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    await Storage.put(moment().valueOf() + '-' + file.name, file, {
      bucket: 'panda-image-storage-dev',
      level: 'public',
      progressCallback(progress) {
        onProgress(
          {
            percent: Math.round(
              (progress.loaded / progress.total) * 100
            ).toFixed(2),
          },
          file
        );
      },
    })
      .then((result) => {
        onSuccess(result, file);
        let fileList = [...imageList];

        const fileIndex = fileList.findIndex((item) => item.uid === file.uid);
        if (fileIndex > -1) {
          fileList[fileIndex] = {
            uid: result.key,
            name: file.name,
            url: URL.imageUrl + result.key,
          };
          data.images = fileList;
          setImageList(fileList);
          if (data.PK === 'popup') {
            handleSetPost('setPopup', data);
          } else {
            handleSetPost('setImageSlider', data);
          }
        }
      })
      .catch(onError);
    return {
      abort() {
        console.log('upload progress is aborted.');
      },
    };
  };

  const handleSetLink = async (linkUrl) => {
    try {
      setIsSavingLink(true);
      const imageIndex = data.images.findIndex(
        (item) => item.uid === previewImage.uid
      );
      if (imageIndex > -1) {
        data.images[imageIndex] = {
          ...previewImage,
          linkUrl,
        };
        const res = await handleSetPost('setImageSlider', data);
        if (res) {
          setIsSavingLink(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Upload
        customRequest={handleUploadRequest}
        listType='picture-card'
        fileList={imageList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {imageList.length >= limit ? null : uploadButton}
      </Upload>
      <Modal
        centered
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {previewImage && (
          <>
            <img
              alt='preview'
              style={{ width: '100%' }}
              src={previewImage.url}
            />
            <div style={{ marginTop: 50 }}>
              <Input.Search
                placeholder='예시) https://company-pandakorea.com/'
                allowClear
                enterButton='링크 저장'
                defaultValue={previewImage.linkUrl}
                loading={isSavingLink}
                onSearch={handleSetLink}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ImageWall;
